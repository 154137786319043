<!--  -->
<template>
    <div class='page-task-task-subject-summary flex-center'>
        <component-bcg url="task/c1.png"></component-bcg>
        <div class="bcg">
            <div class="board">
                <div class="task-room-bcg">
                    <div class="task-room flex-center" >
                        <span class="text-large-c text-purple-color bold room chapter">{{routeDataCul.chapterName}}</span>
                    </div>
                </div>
                <div class="task-content-bcg">
                    <div class="task-content" v-show="!listLength&&loadingFlag">
                        <div class="content-mark"></div>
                        <div class="items-empty flex-center max-size ">
                            <span class="text-normal bold title">{{'题库暂未开放，敬请期待~'}}</span>
                        </div>
                    </div>
                    <div class="task-content" v-show="!listLength&&!loadingFlag">
                        <div class="content-mark"></div>
                        <div class="items-empty flex-center max-size ">
                            <span class="text-normal bold title">{{'题库正在紧急加载 稍等片刻~'}}</span>
                        </div>
                    </div>
                    <div class="task-content" v-show="listLength">
                        <div class="content-mark"></div>
                        <div class="items mini-scrollbar a" id="task-subject-chapter-a">
                            <div class="items-box" ref="commentBox">
                                <div class="item" v-for="(v,i) in sectionListCul" :key="i">
                                    <div class="item-bcg scale-button" @click="gotoSection(v)">
                                        <span class="text-normal-b bold b text-yel-color">{{v.sectionName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="items mini-scrollbar b" id="task-subject-chapter-b">
                            <div class="items-bottom-box" ref="commentBox">
                                <div class="item" v-for="(v,i) in sectionListCul" :key="i">
                                    <div class="item-bcg scale-button">
                                        <span class="text-normal-b bold b text-yel-color">{{v.sectionName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import apiClass from '@/API/page/task'
    import ComponentBcg from '../../../components/componentBcg.vue'
    export default {
        name: '',
        props: {
        },
        data() {
            return {
                api: new apiClass(),
                routeData: this.$route.params.routeData,
                chapterNo: this.$route.params.chapterNo,
                chapterName: this.$route.params.chapterName,
                courseId: this.$route.params.courseId,
                headVal: this.$route.params.headVal,
                sectionList: [],
                loadingFlag: false,
                tagTypeVal: 2,
            };
        },
        methods: {
            async pageInit(){
                this.subjectSectionList()
                this.eventSet()
            },
            async subjectSectionList(){
                this.loadingFlag = false
                let {routeDataCul,chapterNo,courseId} = this
                let opt = {
                    school_id: this.api.PARAMS.school_id,
                    course_id: routeDataCul.courseId,
                    chapter_no: routeDataCul.chapterNo,
                }
                let res = await this.api.subjectSectionList(opt)
                if(!res) return
                this.loadingFlag = true
                this.sectionList = res
            },
            eventSet(){
                this.eventOff()
                // this._common.eventSet('taskChapterBackFun', e => {
                //     this.back()
                // })
            },
            eventOff(){
                // this._common.eventRemove('taskChapterBackFun')
            },
            back(){
                let {routeDataCul,tagTypeVal} = this
                let name = 'task-summary'
                let params = {
                    headVal: routeDataCul.courseId,
                    subject: routeDataCul.courseId,
                }
                let query = { tagTypeVal }
                let type = 'replace'
                this._routeHand.goto({name, type, params, query})
            },
            gotoSection(opt){
                let { routeDataCul } = this
                let name = 'task-subject-section'
                let type = 'replace'
                let params = {
                    courseId: routeDataCul.courseId,
                    chapterNo: routeDataCul.chapterNo,
                    chapterName: routeDataCul.chapterName,
                    sectionNo: opt.sectionNo,
                    sectionName: opt.sectionName,
                    id: opt.id,
                }
                params = {
                    routeData: this._common.encodeData(params)
                }
                this._routeHand.goto({name, type, params})
            },
            scrollHander(){
                document.getElementById('task-subject-chapter-b').scrollTop = document.getElementById('task-subject-chapter-a').scrollTop
            },
            scrollListener(){
                let eleA = document.getElementById('task-subject-chapter-a')
                let eleB = document.getElementById('task-subject-chapter-b')
                if(eleA && eleB) eleA.addEventListener("scroll", this.scrollHander);
            },

        },
        created() {
            this.pageInit()
        },
        components: {
            ComponentBcg,
        },
        computed: {
            sectionListCul(){
                let list = this._common.deepCopy(this.sectionList)
                return list
            },
            listLength(){
                let {sectionListCul:list} = this
                return list.length
            },
            routeDataCul(){
                let {routeData} = this
                return this._common.routeDataCul(routeData)
            }
        },
        watch: {
            $route: {
                handler(){
                    // this.routeData = this.$route.params.routeData
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
            this.scrollListener()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' scoped>
    @text-yel-color-empty:#8D7461;
    @text-yel-color:#734325;
    @text-purple-color:#59578E;
    @text-red-color:#991B3D;
    @text-pur-color:#6143AF;
    .text-pur-color{
        color: @text-pur-color;
    }
    .text-red-color{
        color: @text-red-color;
    }
    .text-yel-color{
        color: @text-yel-color;
    }
    .text-purple-color{
        color: @text-purple-color;
    }
    .chapter-result-box{
        position: relative;
        width: 285px;
        height: 323px;
    }
    .page-task-task-subject-summary{
        .bcg{
            .background-img-max(url('@{assetsUrlV2_0}/task/c2.png'));
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 638px;
            height: 375px;
            .board{
                .background-img-max(url('@{assetsUrlV2_0}/task/c3.png'));
                width: 621px;
                height: 360px;
                position: absolute;
                top: 10px;
                left: 10px;
                .task-room-bcg{
                    .background-img-max(url('@{assetsUrlV2_0}/task/c4.png'));
                    width: 209px;
                    height: 43px;
                    position: absolute;
                    top: 2px;
                    left: 206px;
                    .task-room{
                        .background-img-max(url('@{assetsUrlV2_0}/task/c5.png'));
                        width: 193px;
                        height: 43px;
                        position: absolute;
                        top: -11px;
                        left: 7px;
                        .room{
                            position: relative;
                            top: -1px;
                        }
                        .chapter{
                            letter-spacing: 5px;
                            margin-right: -5px;
                        }
                    }
                }
                .task-label{
                    position: absolute;
                    left: 33px;
                    bottom: 305px;
                    z-index: 2;
                    .homework{
                        .background-img-max(url('@{assetsUrlV2_0}/task/c17.png'));
                        width: 95px;
                        height: 36px;
                        position: absolute;
                        left: 0;
                        bottom: -1px;
                        .c_18{
                            cursor: pointer;
                            .background-img-max(url('@{assetsUrlV2_0}/task/c18_0.png'));
                            width: 89px;
                            height: 29px;
                            position: absolute;
                            bottom: 4px;
                            left: 4px;
                            .txt{
                                font-size: 12px;
                                margin-left: -10px;
                                color: @text-red-color;
                            }
                        }
                    }
                    .homework.on{
                        width: 109px;
                        height: 42px;
                        bottom: -2px;
                        .c_18{
                            .background-img-max(url('@{assetsUrlV2_0}/task/c18.png'));
                            width: 102px;
                            height: 34px;
                            .c_18_light{
                                .background-img-max(url('@{assetsUrlV2_0}/task/c18_light.png'));
                                width: 84px;
                                height: 24px;
                                position: absolute;
                                left: 5px;
                                top: 5px;
                                .txt{
                                    font-size: 15px;
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                    .topic{
                        .background-img-max(url('@{assetsUrlV2_0}/task/c17.png'));
                        width: 95px;
                        height: 36px;
                        position: absolute;
                        left: 83px;
                        z-index: -1;
                        bottom: -1px;
                        .c_19{
                            cursor: pointer;
                            .background-img-max(url('@{assetsUrlV2_0}/task/c19.png'));
                            width: 89px;
                            height: 29px;
                            position: absolute;
                            bottom: 4px;
                            left: 3px;
                            .txt{
                                font-size: 12px;
                                color: @text-pur-color;
                            }
                        }
                        .c_19.not{
                            .background-img-max(url('@{assetsUrlV2_0}/task/c19_1.png'));
                        }
                    }
                    .topic-bcg{
                        .background-img-max(url('@{assetsUrlV2_0}/task/c17.png'));
                        width: 109px;
                        height: 42px;
                        position: absolute;
                        left: 65px;
                        bottom: -1px;
                        z-index: -1;
                    }
                    .topic.on{
                        background: none;
                        width: 109px;
                        height: 42px;
                        left: 65px;
                        z-index: 1;
                        .c_19{
                            .background-img-max(url('@{assetsUrlV2_0}/task/c19_0.png'));
                            width: 102px;
                            height: 34px;
                             .c_19_light{
                                .background-img-max(url('@{assetsUrlV2_0}/task/c19_light.png'));
                                width: 78px;
                                height: 24px;
                                .txt{
                                    font-size: 14px;
                                }
                            }
                        }
                        .c_19.not{
                            .background-img-max(url('@{assetsUrlV2_0}/task/c19_1.png'));
                        }
                    }
                }
                .task-content-bcg{
                    .background-img-max(url('@{assetsUrlV2_0}/task/c22.png'));
                    width: 580px;
                    height: 309px;
                    position: absolute;
                    top: 45px;
                    left: 21px;
                    .task-content{
                        width: 521px;
                        height: 245px;
                        position: absolute;
                        top: 25px;
                        left: 24px;
                        .background-img-max(url('@{assetsUrlV2_0}/task/c24.png'));
                        .items-empty{
                            .title{
                                color: @text-yel-color-empty;
                            }
                        }
                        .content-mark{
                            .background-img-max(url('@{assetsUrlV2_0}/task/c23.png'));
                            width: 521px;
                            height: 245px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 2;
                            pointer-events: none;
                        }
                        .item.a{
                            pointer-events: none;
                            z-index: 1;
                        }
                        .items.b{
                            pointer-events: none;
                            z-index: 3;
                            .items-bottom-box{
                                visibility: hidden;
                            }
                        }
                        .items{
                            width: 526px;
                            height: 244px;
                            position: absolute;
                            // padding-top: 5px;
                            .items-box{
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                padding: 10px 15px;
                                .item{
                                    width: 25%;
                                    height: 80px;
                                    position: relative;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    .item-bcg{
                                        .background-img-max(url('@{assetsUrlV2_0}/task/c25.png'));
                                        width: 104px;
                                        height: 64px;
                                        border-radius: 8px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        .b{
                                            display: inline-block;
                                            height: auto;
                                        }
                                    }
                                }
                            }
                            .items-bottom-box{
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                padding: 10px 15px;
                                .item{
                                    width: 25%;
                                    height: 80px;
                                    position: relative;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    .item-bcg{
                                        // .background-img-max(url('@{assetsUrlV2_0}/task/c25.png'));
                                        width: 104px;
                                        height: 64px;
                                        border-radius: 8px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        .b{
                                            display: inline-block;
                                            height: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    /deep/.com-confirm-button{
        width: 100%;
        height: 100%;
    }
    // 滚动条
    .mini-scrollbar::-webkit-scrollbar{
        width: 10px;
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
    }
    .mini-scrollbar-x::-webkit-scrollbar{
        height: 4px;
    }
    .mini-scrollbar::-webkit-scrollbar-thumb, .mini-scrollbar-x::-webkit-scrollbar-thumb{
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        // background: rgba(0,0,0,0.2);
        .background-img-max(url('@{assetsUrlV2_0}/task/c26.png'));
        // background: #FFFAE6;
        // background: url('@{assetsUrlV2_0}/avatar/c30.png') no-repeat;
        // background-position: center;
        
    }
    .mini-scrollbar::-webkit-scrollbar-track-piece{
        // background: red;
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
    }
    .mini-scrollbar::-webkit-scrollbar-thumb::before{
        content: '';
        .background-img-max(url('@{assetsUrlV2_0}/task/c26.png'));
    }
    .mini-scrollbar::-webkit-scrollbar-track, .mini-scrollbar-x::-webkit-scrollbar-track{
        // -webkit-box-shadow: inset 0 0 5px #C7946A;
        border-radius: 10px;
        // background: rgba(178,123,85,0.1);
        // background: #B27B55;
        background-image: linear-gradient(to right, #D2B7A2, #DDCDB8, #D2B7A2);
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
    }
    .mini-scrollbar{
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>